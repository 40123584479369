import logo from "./logo.png";
import play from "./play.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <br />
        <br />
      </header>
      <a href="https://play.google.com/store/apps/details?id=com.advancedopsnativeapp" target="_blank" rel="noreferrer">
        <img src={play} className={"storeLink"} alt="Play Store Link" />
      </a>
      <br />
      <br />
      <br />
      <br />
      <br />
      <div>
        <a className={"App-link"} href="/terms.html" target="_blank">
          Terms &amp; Conditions
        </a>
        <span className={"spacer"}></span>
        <a className={"App-link"} href="privacy.html" target="_blank">
          Privacy Policy
        </a>
      </div>
      <br />
      <br />
    </div>
  );
}

export default App;
